@tailwind base;
@tailwind components;
@tailwind utilities;

:fullscreen,
::backdrop {
  background-color: rgba(255, 255, 255, 0);
}

body::-webkit-scrollbar {
  display: none;
}
